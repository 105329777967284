import { useStaticQuery, graphql } from "gatsby";
import preview from "@pagerland/themes/src/RealEstate/assets/backgroundcourses-1.jpg";
import React from "react";

import Theme, { theme } from "@pagerland/themes/src/RealEstate";
import { Helmet } from "react-helmet";
import SEO from "../components/SEO";
import {
  Footer,
  About,
  Reviews,
  Course,
  MontessoriCourses,
  Navbar,
  WelcomeCourses,
} from "@pagerland/themes/src/RealEstate/containers";

import PropTypes from "prop-types";

export default function Montessori({ url }) {
  const data = useStaticQuery(graphql`
    query CourseInfo {
        allMarkdownRemark(filter: {frontmatter: {category: {eq: "course"}}})  {
          nodes {
            html
            frontmatter {
              slug
              title
              category
              tags
              thumb {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            id
          }
        }
      }`)
  return (
    <Theme>
      <Helmet>
        <link href={theme.typography.googleFont} rel="stylesheet" />
        <meta name="theme-color" content={theme.colors.primary} />
        <meta property="og:image" content={`${url}${preview}`} />
      </Helmet>
      <SEO title="Montessori" />
      <Navbar />
      <WelcomeCourses name="" homepage="1" />
      <MontessoriCourses name="register" data={data} />
      <Reviews name="reviews" />
      <Course name="venue" />
      <About name="about" />
      <Footer name="footer" />
    </Theme>
  )

}


Montessori.propTypes = {
  url: PropTypes.string,
};

Montessori.defaultProps = {
  url: "https://www.instagram.com/practicalmontessori/",
};

//export default Montessori;
